/* Easter egg overlay CSS */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 1001;
  filter: invert(1);
}

.close-button img {
  width: 30px;
  height: 30px;
}

.gif-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.gif-container img {
  max-width: 100%;
  max-height: 80vh;
}