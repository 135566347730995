:root {
  --text-color: #ffffff;
  --link-color: #FF6442;
}

.header-content {
  border-bottom: 5px dotted var(--link-color);
}

.header-banner {
  width: 300px;
  cursor: pointer;
}

.header-title,
.header-paragraph {
  margin: 0px;
  padding: 0.5em 0;
  color: var(--text-color);
}

.link-text {
  color: var(--link-color);
  margin-left: 5px;
  margin-right: 5px;
}

.link-text:hover {
  color: black;
}

.link-emoji {
  margin-left: 5px;
}

ul {
  list-style-type: none;
  margin: 0px;
  margin-bottom: 1.5em;
  padding: 0px;
  display: flex;
  justify-content: center;
}

a {
  text-decoration: none;
  font-size: 1.25rem;
}

.button-style,
.cv-button-style {
  background-color: #F2CC8F;
  color: black;
  padding: 1px 5px;
  border-radius: 10px;
  font-size: larger;
  font-weight: bold;
  margin-right: 10px;
  margin-left: 10px;
  border: 1px solid var(--link-color);
}