:root {
  --link-color: #FF6442;
}

.footer-component {
  border-top: 5px dotted var(--link-color);
}

.footer-component-text {
  margin: 1.5em;
  color: white;
  font-size: small;
}