.links-component {
  border-top: 3px dotted #ddd;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 10px;
  width: 25vw;
  margin: 1.5em auto;
}

.links-title {
  color: #ffffff;
  font-size: larger;
  margin: 1em 0 0 0;
}

.github-link,
.linkedIn-link {
  display: flex;
  align-items: center;
}

.github-link:hover,
.linkedIn-link:hover {
  filter: invert(100%);
}

.links-text {
  margin: 0.5em;
  color: white;
  font-size: medium;
  margin-left: 10px;
}

.github-link img,
.linkedIn-link img {
  float: left;
  max-width: 35px;
  height: 35px;
}

/* Media query for different screen sizes */
@media (max-width: 966px) {
  .links-component {
    border-top: 3px dotted #ddd;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
    min-width: 243px;
    margin: 1.5em auto;
  }
}