:root {
  --text-color: #ffffff;
}

.project-component {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 40px;
  max-width: 65vw;
  margin: 1.5em auto;
}

.project-element {
  box-sizing: border-box;
}

.project-element img {
  cursor: pointer;
}

.project-element img:hover {
  opacity: 0.50;
}

.project-feature img {
  height: 150px;
  width: 300px;
}

.project-feature-name {
  max-width: 300px;
  min-height: 40px;
  font-size: larger;
  word-wrap: break-word;
  margin: 0 auto;
  text-align: center;
  color: var(--text-color);
}

.project-feature-description {
  max-width: 300px;
  min-height: 85px;
  word-wrap: break-word;
  margin: 0 auto;
  text-align: left;
  color: var(--text-color);
}

/* Media query for different screen sizes */
@media (max-width: 768px) {
  .project-component {
    grid-template-columns: 1fr;
  }
}