body {
  background-color: #2D3142;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 18px;
  line-height: 160%;
  display: grid;
  place-items: center;
}

.app,
.skills,
.projects,
.header-component,
.project-component,
.links-component,
.footer-component {
  align-items: center;
  text-align: center;
  justify-content: center;
}