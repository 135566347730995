.project-button {
  color: white;
  font-size: 1rem;
  background-color: #344966;
  border: 2px solid #e6aace;
  border-radius: 0.5rem;
  padding: 5px 10px;
  margin: 5px;
  cursor: pointer;
}

.project-button.selected {
  background-color: black;
  color: red;
}

.skills-component {
  margin: 2em 0;
}

.line-graph {
  margin: 0.5em 0;
}